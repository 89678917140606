module.exports = {
  API_URL: 'https://sites.au.siteloft.com/api/v1',
  WP_URL: 'https://wpa.au.siteloft.com',
  ADMIN_URL: 'https://sites.au.siteloft.com/admin',
  CLIENT_ID: '70d8f31a-9405-11ea-8e2c-86e69f94f2ca',
  CLIENT_SECRET: 'NrlhDKK4LimsokUvcl5L2dwtQRp5suILW7MIlgel',

  SEGMENT_ID: 'Gd6FsFhtviiAYvbiAync9eIE6GUfGIJK',
  SPRIG_ID: 'vUiVEzD0a1'
};
